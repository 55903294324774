import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  EnvironmentPartial,
  QaIdPartial,
  CodeSnippet,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Legend = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Legend;
    return <Component {...props} />;
  },
});

const IconEffectArrow = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconEffectArrow;
    return <Component {...props} />;
  },
});

const IconEffectFreehand = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconEffectFreehand;
    return <Component {...props} />;
  },
});

const IconUiDismissSmall = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconUiDismissSmall;
    return <Component {...props} />;
  },
});

const legendSnippet = require('raw-loader!../../../../data/snippets/legend.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Legend"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-data-visualization--docs#legend"
      />
      <CodeSnippet
        scope={{
          React: React,
          IconEffectArrow: IconEffectArrow,
          IconEffectFreehand: IconEffectFreehand,
          IconUiDismissSmall: IconUiDismissSmall,
          Legend: Legend,
        }}
        code={legendSnippet}
        platform="react"
        gitHubLink="link"
      />

      <Section title="Props">
        <PropList header="Visual">
          <ClassnamePartial componentName="legend" />

          <EnvironmentPartial />

          <PropListItem name="groups" types={['Group[]']} isRequired>
            <Text>The items shown in the legend.</Text>
            <List type="unordered">
              <li>
                <code>category</code> - the name of a group of legend items -
                not required
              </li>
              <li>
                <code>items</code> - an array of legend items to display
                <ul style={{ marginLeft: 16 }}>
                  <li>
                    <code>symbol</code> - an element to signify the legend item
                  </li>
                  <li>
                    <code>label</code> - the text shown on the legend item
                  </li>
                </ul>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial componentName="legend" />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Data Visualization / Legend"
      />
    </PlatformTab>
  );
};

export default WebTab;
